import React, { Fragment, useEffect } from "react";
import _pick from "lodash/pick";
import useSetState from "react-use/lib/useSetState";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import CardHeader from "../../../components/CardHeader";
import Editor from "../../../components/Editor";
import { useGathering } from "../../../contexts/GatheringContext";
import { GET_GATHERING_INFO, UPDATE_GATHERING } from "../../../graphql/Gathering";
import { GatheringBreadcrumbs } from "../GatheringEdit";

const MAX_LENGTH = 90;

const CharacterCounter = ({ current, max }) => {
  const getColor = () => {
    if (current > max) return "#dc3545";
    if (current > max * 0.8) return "#fd7e14";
    return "#6c757d";
  };

  return (
    <div className="d-flex justify-content-end mt-1">
      <small style={{ color: getColor() }}>
        {current}/{max} characters
        {current > max && " (limit exceeded)"}
      </small>
    </div>
  );
};

const initialData = {
  ID: null,
  Title: "",
  Description: "",
  Language: "",
  titleError: "",
  descriptionError: "",
};

const GatheringDescription = () => {
  const { t } = useTranslation(["common", "gatherings"]);
  const history = useHistory();
  const { data: gathering } = useGathering();
  const [state, setState] = useSetState({ ...initialData });

  useEffect(() => {
    const update = _pick(gathering, ["ID", "Title", "Description", "Language"]);
    setState({
      ...initialData,
      ...update,
    });
  }, [gathering]);

  const [saveTime, { loading: isSaving }] = useMutation(UPDATE_GATHERING, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_GATHERING_INFO,
        variables: {
          ID: gathering.ID,
        },
      },
    ],
    onCompleted: () => {
      history.replace(`/gathering/${gathering.ID}`);
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (isSaving) {
      return;
    }

    // Validate title and description
    let valid = true;
    if (state.Title && state.Title.length > MAX_LENGTH) {
      setState({ titleError: t("common:titleTooLong") });
      valid = false;
    } else {
      setState({ titleError: "" });
    }

    if (!valid) {
      return;
    }

    // Only send the fields that the GraphQL schema expects
    const inputData = _pick(state, ["ID", "Title", "Description", "Language"]);
    return saveTime({ variables: { Input: inputData } });
  };

  const handleChange = (e) => {
    if (typeof e === "string") {
      if (e.length <= MAX_LENGTH) {
        return setState({
          ...state,
          Description: e,
          descriptionError: "",
        });
      } else {
        return setState({
          ...state,
          Description: e, // Still update the value so user can edit it down
          descriptionError: t("common:descriptionTooLong"),
        });
      }
    } else {
      const { name, value } = e.currentTarget;
      if (name === "Title" && value.length > MAX_LENGTH) {
        return setState({
          ...state,
          [name]: value, // Still update the value so user can edit it down
          titleError: t("common:titleTooLong"),
        });
      } else if (name === "Title") {
        setState({
          ...state,
          [name]: value,
          titleError: "",
        });
      } else {
        setState({
          ...state,
          [name]: value,
          descriptionError: "",
        });
      }
    }
  };

  // Calculate description text length (handling both string and HTML content)
  const getDescriptionLength = () => {
    if (!state.Description) return 0;

    // For HTML content from Editor component, create a temporary element
    // to extract text content without HTML tags
    if (state.Description.includes('<')) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = state.Description;
      return tempDiv.textContent.length;
    }

    return state.Description.length;
  };

  // Safe way to get Title length without optional chaining
  const getTitleLength = () => {
    if (!state.Title) return 0;
    return state.Title.length;
  };

  return (
    <Fragment>
      <GatheringBreadcrumbs />
      <div className="card">
        <CardHeader
          title={t("gatherings:topic")}
          onDismiss={() => history.push(`/gathering/${gathering.ID}`)}
          onSubmit={onSubmit}
        />
        <div className="card-body">
          <form className="" onSubmit={onSubmit}>
            {state.titleError && (
              <div className="alert alert-danger">
                {state.titleError}
              </div>
            )}
            <div className="form-group">
              <label htmlFor="title-name" className="text-primary">
                {t("common:title")}
              </label>
              <input
                id="title-name"
                type="text"
                className="form-control"
                name="Title"
                value={state.Title || ''}
                onChange={handleChange}
                maxLength={MAX_LENGTH} // Set max length for the input field
              />
              <CharacterCounter
                current={getTitleLength()}
                max={MAX_LENGTH}
              />
              <small className="form-text text-muted">
                {t("gatherings:titledescription")}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="description-area" className="text-primary">
                {t("common:description")}
              </label>
              <Editor
                id="description-area"
                textareaName="Description"
                value={state.Description || ''}
                onEditorChange={handleChange}
              />
              <small className="form-text text-muted">
                {t("gatherings:description:maxLength", { maxLength: MAX_LENGTH })}
              </small>
            </div>
            <div className="form-group">
              <label className="text-primary" htmlFor="language-switch">
                {t("common:language")}
              </label>
              <select
                id="language-switch"
                className="form-control"
                value={state.Language || ''}
                name="Language"
                onChange={handleChange}
              >
                <option value={"nl_NL"}>{t("common:netherlands")}</option>
                <option value={"en_US"}>{t("common:english")}</option>
              </select>
              <small className="form-text text-muted">
                {t("gatherings:description:languagedesc")}.
              </small>
            </div>
            <div className="d-flex">
              <button
                type="submit"
                className="btn btn-secondary"
                disabled={isSaving || getTitleLength() > MAX_LENGTH}
              >
                {isSaving && (
                  <span
                    className="spinner-border spinner-border-sm mr-2 mb-1"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                {t("common:save")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default GatheringDescription;